

































import Vue from "vue";
export default Vue.extend({
  name:"the-default-dialog",
  props: {
    title: {
      type: String,
      required: true,
    },
    body: {
      type: String,
      required: true,
    },
    cancelText:{
      type:String
    },
    acceptText:{
      type:String
    }
  },
  data () {
    return {
      isOpen:false
    }
  },
  methods:{
    show(){
      this.isOpen = true;
    },
    hide(){
      this.isOpen = false;
    }
  }
});
